<template>
  <div>
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{$trans('Reports')}}
      </span>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Group') }}</label>
            <Select2 class="w-200" v-model="filterQuery.groups" :options="project_groups" :settings="{  multiple:true}"/>
          </div>
          <div class="form-group mr-3">
            <label class="d-block">{{ $trans('From date') }}</label>
            <vuejs-datepicker  input-class="form-control" class="w-200" v-model="filterQuery.from"></vuejs-datepicker>
          </div>
          <div class="form-group  mr-3">
            <label class="d-block">{{ $trans('To date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.to"></vuejs-datepicker>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
          <download-csv v-if="json_data && json_data.length" :labels="labels" :data="json_data" :name="export_file_name">
            <button class="btn btn-sm btn-primary">{{ $trans('Download') }}</button>
          </download-csv>
        </div>
      </div>
    </div>
    <div class="card">
        <div class="card-body">
          <div v-if="results" class="table-responsive">
            <table class="table table-striped mb-3">
              <thead>
              <tr>
                <th rowspan="2">{{$trans('Project name')}}</th>
                <th rowspan="2">{{$trans('Group')}}</th>
                <th colspan="2" class="text-right" v-for="(s,k) in services" :key="k">{{$trans(s)}}</th>
                <th colspan="2" class="text-right">{{$trans('Total amount')}}</th>
              </tr>
              <tr>
                <template v-for="(s,code) in services" >
                  <td class="text-right" :key="code+'_vnd'">VND</td>
                  <td class="text-right" :key="code+'_usd'">USD</td>
                </template>
                  <td class="text-right">VND</td>
                  <td class="text-right">USD</td>
              </tr>
              </thead>
              <tbody>
                  <tr v-for="(result,k) in results" :key="k">
                    <td>
                      <slot v-if="result.project">
                        {{result.project.name}}
                      </slot>
                    </td>
                    <td>
                      <slot v-if="result.project">
                        {{result.project.group}}
                      </slot>
                    </td>
                    <template v-for="(s,code) in services" >
                      <td class="text-right" :key="code+'y'">
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency=""
                             :value="result[code]"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                      </td>
                      <td class="text-right" :key="code+'x'">
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency=""
                            :value="result[code+'_usd']"
                            v-bind:read-only="true"
                            v-bind:precision="2">
                        </vue-numeric>
                      </td>
                    </template>
                    <td class="text-right">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          currency=""
                          v-model="result.total"
                          v-bind:read-only="true"
                          v-bind:precision="0">
                      </vue-numeric>
                    </td>
                    <td class="text-right">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          currency=""
                          v-model="result.total_usd"
                          v-bind:read-only="true"
                          v-bind:precision="2">
                      </vue-numeric>
                    </td>
                  </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2">{{$trans('Total amount')}}:</th>
                  <template v-for="(s,k) in services">
                    <td class="text-right" :key="k+'y'">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          currency=""
                          :value="report_total[k]"
                          v-bind:read-only="true"
                          v-bind:precision="0">
                      </vue-numeric>
                    </td>
                    <td class="text-right" :key="k+'x'">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          currency=""
                          :value="report_total[k+'_usd']"
                          v-bind:read-only="true"
                          v-bind:precision="2">
                      </vue-numeric>
                    </td>
                  </template>

                  <td class="text-right">
                    <vue-numeric
                        currency-symbol-position="suffix"
                        currency=""
                        v-model="report_total.total"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                    </vue-numeric>
                  </td>
                  <td class="text-right">
                    <vue-numeric
                        currency-symbol-position="suffix"
                        currency=""
                        v-model="report_total.total_usd"
                        v-bind:read-only="true"
                        v-bind:precision="2">
                    </vue-numeric>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Reports",
  data:function (){
    return {
      formFilter:false,
      process_loading:false,
      filterQuery:{
        from: null,
        to: new Date(),
        groups:''
      },
      reports_data:null
    }
  },
  mounted() {
    var today = new Date();
    this.filterQuery.from = new Date(today.getFullYear(), today.getMonth(), 1)
  },
  methods:{
    index:function (){
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/reports',params:this.filterQuery, method: 'GET'})
          .then(resp => {
            this.reports_data = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter:function () {
      var today = new Date();
      this.filterQuery = {
        from: new Date(today.getFullYear(), today.getMonth(), 1),
        to: new Date(),
        groups:[]
      }
      this.index()
    }
  },
  created() {
    this.index()
  },
  computed:{
    results:function (){
      let results =[]
      if(this.reports_data){
        results = this.reports_data.results
      }
      return results
    },
    services:function (){
      return this.$root.$data.global_settings.services
    },
    report_total:function (){
        if( !this.results && this.services){
            return  {}
        }
        let report_total ={
            total:0,
            total_usd:0
        }
        let results = this.results
        let services = this.services
        Object.keys(services).forEach(function(key) {
          report_total[key] = 0
          report_total[key+'_usd'] = 0
        });
        results.forEach((el)=>{
          report_total['total'] = report_total['total']+el.total
          report_total['total_usd'] = report_total['total_usd']+el.total_usd

          Object.keys(services).forEach(function(key) {
            report_total[key] = report_total[key] + el[key]
            report_total[key+'_usd'] = report_total[key+'_usd'] + el[key+'_usd']
          });

        })

        return report_total;
    },
    labels:function (){
      let services = this.services
      let labels ={
        project_name:'PROJECT NAME',
        group:'GROUP',
      }
      Object.keys(services).forEach(function(key) {
        labels[key] = services[key].toUpperCase()
      });
      labels['total'] ='TOTAL AMOUNT'
      return labels
    },
    json_data:function () {
      let json_data =[]
      let services = this.services
      if( this.results){
        let results =this.results
        results.forEach((el)=>{
            let item = {
              project_name:el.project.name,
              group:el.project.group,
            }
            Object.keys(services).forEach(function(key) {
              item[key] = el[key]
            });
            item['total'] = el.total
            json_data.push(item)
        })
      }
      return json_data
    },
    export_file_name:function (){
      let from = ''
      let to = ''
      from = moment(this.filterQuery.from).format('YYYY-MM-DD')
      to = moment(this.filterQuery.to).format('YYYY-MM-DD')
      return 'project-reports-' + from + '-' + to + '.csv';
    },
    project_groups:function (){
      return this.$root.$data.global_settings.project_groups
    }
  }
}
</script>


<style scoped>

</style>